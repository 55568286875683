import React from "react"
import styled from "styled-components"
import { down, up } from "styled-breakpoints"
//DATA
import { info } from "../assets/data/kontakt"
//STYLES
import { H1, H4, Text, LabelBW } from "../utils/typo"
import { Section2, ContainerMob } from "../utils/utils"
//COMPONENTS
import Seo from "../components/seo/SEO"

const Baner = styled.div`
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 7rem;

  ${down("sm")} {
    grid-template-columns: 1fr;
    grid-row-gap: 1rem;
  }

  p {
    white-space: pre-wrap;
  }

  iframe {
    filter: grayscale(100%);
    ${down("sm")} {
      display: none;
    }
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const MapMobile = styled.div`
  margin-top: 4rem;
  > div {
    height: 300px;
  }

  iframe {
    filter: grayscale(100%);
    height: 300px;
  }

  ${up("md")} {
    display: none;
  }
`

const Kontakt = () => {
  return (
    <div>
      <Seo title="Kontakt" slug="/kontakt" />
      <Section2
        pt={6}
        pb={3}
        tabIndex="0"
        role="contentinfo"
        aria-label="Dane kontaktowe i mapa dojazdu"
      >
        <ContainerMob>
          <Baner>
            {/* <MapContainer /> */}
            <iframe
              title="map"
              width="100%"
              height="100%"
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
              src="https://www.openstreetmap.org/export/embed.html?bbox=18.676784634590152%2C50.28876344904422%2C18.68032515048981%2C50.29032290276343&amp;layer=mapnik&amp;marker=50.28954318229204%2C18.678554892539978"
            />

            <TextWrapper>
              <LabelBW mb="1rem">Kontakt</LabelBW>
              <H1>Zostańmy w kontakcie!</H1>
              <Text>— Zapraszamy do kontaktu</Text>
              <H4 mt="2rem">+48 {info.tel}</H4>
              <H4>{info.mail}</H4>

              <LabelBW mb="1rem" mt="3rem">
                Adres
              </LabelBW>
              <p>
                <b>{info.wydzial}</b>
              </p>
              <p>
                ul. {info.ulica} {info.nrBud}
              </p>
              <p>
                {info.kod} {info.miasto}
              </p>
              <p>{info.woj}</p>
              <p>{info.kraj}</p>

              <LabelBW mb="1rem" mt="3rem">
                Firma
              </LabelBW>
              <p>
                <b>NIP: </b>
                {info.nip}
              </p>
              <p>
                <b>REGON: </b>
                {info.regon}
              </p>
              <p>
                <b>KRS: </b>
                {info.krs}
              </p>
            </TextWrapper>
          </Baner>

          <MapMobile>
            <iframe
              title="mapMobile"
              width="100%"
              height="100%"
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
              src="https://www.openstreetmap.org/export/embed.html?bbox=18.676784634590152%2C50.28876344904422%2C18.68032515048981%2C50.29032290276343&amp;layer=mapnik&amp;marker=50.28954318229204%2C18.678554892539978"
            />
          </MapMobile>
        </ContainerMob>
      </Section2>
    </div>
  )
}
export default Kontakt
